/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import "./App.scss";
import { AsinsPage } from "./pages/AsinsPage.tsx";
import { LoginPage } from "./pages/LoginPage.tsx";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Link, Redirect, Route, Switch, useLocation } from "wouter";

import { ProductDetailsPage } from "./pages/ProductDetailsPage.tsx";
import { GenerateDeliveryToAwsPage } from "./pages/GenerateDeliveryToAwsPage.tsx";
import { GenerateDeliveryToAwsPageNew } from "./pages/GenerateDeliveryToAwsPageNew.tsx";
import { WeightCalculator } from "./components/WeightCalculator.tsx";
import { EntryOrdersPage } from "./pages/EntryOrdersPage.tsx";
import { NewEntryOrderPage } from "./pages/NewEntryOrderPage.tsx";
import { WarehousesPage } from "./pages/WarehousesPage.tsx";
import { EntryOrderForm } from "./components/EntryOrderForm/EntryOrderForm.tsx";
import { CreatePack } from "./pages/CreatePack.tsx";
import { Divider } from "antd";
import { LiaElementor } from "react-icons/lia";
import { AiOutlineProduct } from "react-icons/ai";
import { LuWarehouse } from "react-icons/lu";
import { TbTruckDelivery } from "react-icons/tb";
import { CgNotes } from "react-icons/cg";
import { RiLogoutBoxLine, RiRefund2Fill } from "react-icons/ri";
import { IoDocumentOutline, IoSettingsOutline } from "react-icons/io5";
import { NotesPage } from "./pages/NotesPage.tsx";
import { NotesForm } from "./components/NotesForm.tsx";
import { RightLayout } from "./components/RightLayout.tsx";
import { CalendarPage } from "./pages/CalendarPage.tsx";
import { CiCalendar } from "react-icons/ci";
import { MODE } from "./config.ts";
import { RefundsPage } from "./pages/RefundsPage.tsx";
import { Athostests } from "./components/Athostests.tsx";
import { ParentsPage } from "./pages/ParentsPage.tsx";
import { GiFamilyTree } from "react-icons/gi";
import { SettingsPage } from "./pages/SettingsPage.tsx";
import { CheckAllErrorsPage } from "./pages/CheckAllErrorsPage.tsx";
import { FaFirstOrderAlt } from "react-icons/fa";
import { OrderPage } from "./pages/OrderPage.tsx";
import ProductsBuysCalculator from "./components/ProductsBuysCalculator.tsx";
import { EtiquetasSeguridad } from "./components/EtiquetasSeguridad.tsx";

export default function App() {
  const containerRef = useRef(null);

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [location, setLocation] = useLocation();

  const [cookies, setCookie, removeCookie] = useCookies(["dg-token"]);
  // let history = useHistory();

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (cookies["dg-token"]) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${cookies["dg-token"]}`;
      setTimeout(() => {
        setIsLoggedIn(true);
      }, 10);
      // setLocation("/products");
    } else {
      setLocation("/");
      delete axios.defaults.headers.common["Authorization"];
      setIsLoggedIn(false);
    }
  }, [cookies]);

  const handleLogout = () => {
    removeCookie("dg-token");
    delete axios.defaults.headers.common["Authorization"];
    setIsLoggedIn(false);
    setLocation("/");
  };

  const handleSuccessfulLogin = (data) => {
    const { token } = data;
    setCookie("dg-token", token, { path: "/", maxAge: 60 * 60 * 24 * 30 });

    setTimeout(() => {
      // setIsLoggedIn(true);
      setLocation("/calendar");
    }, 1000);
  };

  const stylesLeft = {};
  if (MODE === "development") {
    stylesLeft.backgroundColor = "#8e63fa";
  }

  return (
    <>
      {!isLoggedIn ? (
        <LoginPage onLogin={handleSuccessfulLogin} />
      ) : (
        <div className="app">
          <div className="left" style={stylesLeft}>
            <div className="my-logo">
              <img src="/logo.png" alt="Logo" height={48} width={48} />
            </div>
            <nav
              style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className="d-flex-center">
                <CiCalendar
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/calendar">Calendar</Link>
              </div>
              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <GiFamilyTree
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/parents">Familias</Link>
              </div>
              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <LiaElementor
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/products">Productos</Link>
              </div>
              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <AiOutlineProduct
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/create-pack">Crear pack</Link>
              </div>
              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <LuWarehouse
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/warehouses">Almacenes</Link>
              </div>
              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <TbTruckDelivery
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/generate-delivery-to-aws">Enviar AWS</Link>
              </div>
              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <CgNotes
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/entry-orders">Albaranes</Link>
              </div>

              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <IoDocumentOutline
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/notes">Notas</Link>
              </div>

              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <RiRefund2Fill
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/refunds">Reembolsos</Link>
              </div>

              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <FaFirstOrderAlt
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/order">Pedidos</Link>
              </div>

              <div className="d-flex-center" style={{ marginTop: 12 }}>
                <IoSettingsOutline
                  color="white"
                  fontSize={24}
                  style={{ marginRight: 12 }}
                />

                <Link to="/settings">Configuración</Link>
              </div>

              {MODE === "development" && (
                <div className="d-flex-center" style={{ marginTop: 12 }}>
                  <Link to="/athostests">Athostests</Link>
                </div>
              )}

              <div
                style={{
                  marginTop: "auto",
                  alignSelf: "center",
                  marginBottom: 138,
                }}
              >
                <div style={{ marginTop: 12, marginLeft: 24 }}>
                  <a
                    href="https://www.notion.so/"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex-center"
                  >
                    <img
                      style={{ marginRight: 12 }}
                      alt="Seller board"
                      height={24}
                      width={24}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAAAAABXZoBIAAAA80lEQVR4AZTRIYyDMBjF8efxBn3qfFD1IcGiUFOIyVokAos6h8IjEXNIfD6DqUXOVb7jNnK027pkf9mfeE0LvukjtOtifJRp6BpdqAi3kquDLSJV6KYbJjF/5zXGA8/o6SU4OYhyHzQyX4bupwSWA4FvOMUKpYvVtjlOsqz322QunumXP6A1MpGcK62eUQGoSKPBZ2QOQMj+JWYREIUwzVqgCWFCACaIM5AMIWQJxEG0ADxsdswUyfEB7R0lQm7J3EfKDcdZVm4ZbhX/yM55eHs1Mk84kDmaWp9S51O7AxkDwFda6Lrth8s+gN/BTaMgUWMCAITxMqVHQtVcAAAAAElFTkSuQmCC"
                    />
                    Notion
                  </a>
                </div>
                <div style={{ marginTop: 12, marginLeft: 24 }}>
                  <a
                    href="https://meet.google.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex-center"
                  >
                    <img
                      style={{ marginRight: 12 }}
                      alt="Seller board"
                      height={24}
                      width={24}
                      src="https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v1/web-64dp/logo_meet_2020q4_color_1x_web_64dp.png"
                    />
                    Meet
                  </a>
                </div>
                <div style={{ marginTop: 12, marginLeft: 24 }}>
                  <a
                    href="https://members.helium10.com/user/signin?utm_source=google&utm_medium=cpc&utm_campaign=16503142051&utm_term=helium%2010%20login&utm_content=132069508537-586457439536&hsa_acc=5789278378&hsa_cam=16503142051&hsa_grp=132069508537&hsa_ad=586457439536&hsa_src=g&hsa_tgt=aud-1961565076708:kwd-960702985184&hsa_kw=helium%2010%20login&hsa_mt=e&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=CjwKCAjw8rW2BhAgEiwAoRO5rOvMBkSzqKvFyDWsNqewvuZBmwHJdDzia2fqGmREQkjnyk2e-FqM4BoC_48QAvD_BwE"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex-center"
                  >
                    <img
                      style={{ marginRight: 12 }}
                      alt="Seller board"
                      height={24}
                      width={24}
                      src="https://members.helium10.com/favicon.ico"
                    />
                    Helium10
                  </a>
                </div>

                <div style={{ marginTop: 12, marginLeft: 24 }}>
                  <a
                    href="https://digitalgoodses.atlassian.net/jira/core/projects/AM/board"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex-center"
                  >
                    <img
                      style={{ marginRight: 12 }}
                      alt="Seller board"
                      height={24}
                      width={24}
                      src="https://digitalgoodses.atlassian.net/favicon.ico"
                    />
                    Jira
                  </a>
                </div>

                <div style={{ marginTop: 12, marginLeft: 24 }}>
                  <a
                    href="https://app.sellerboard.com/es/dashboard/"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex-center"
                  >
                    <img
                      style={{ marginRight: 12 }}
                      alt="Seller board"
                      height={24}
                      width={24}
                      src="https://app.sellerboard.com/favicon.ico"
                    />
                    Seller board
                  </a>
                </div>

                <div style={{ marginTop: 12, marginLeft: 24 }}>
                  <a
                    href="https://admin.shopify.com/store/a44053"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex-center"
                  >
                    <img
                      style={{ marginRight: 12 }}
                      alt="Seller board"
                      height={24}
                      width={24}
                      src="https://cdn.shopify.com/shopifycloud/web/assets/v1/favicon-default-6cbad9de243dbae3.ico"
                    />
                    Shopify
                  </a>
                </div>
                <div style={{ marginTop: 12, marginLeft: 24 }}>
                  <a
                    href="https://vatify.marosavat.com/dashboard"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex-center"
                  >
                    <img
                      style={{ marginRight: 12 }}
                      alt="Seller board"
                      height={24}
                      width={24}
                      src="https://vatify.marosavat.com/favicon.ico"
                    />
                    Marosa (Vatify)
                  </a>
                </div>
                <Divider style={{ borderColor: "#eee", width: "200px" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RiLogoutBoxLine
                    color="white"
                    fontSize={24}
                    style={{ marginRight: 12 }}
                  />

                  <a style={{ cursor: "pointer" }} onClick={handleLogout}>
                    Salir
                  </a>
                </div>
              </div>
            </nav>
          </div>

          <div className="right" ref={containerRef}>
            <Switch>
              <Route path="/warehouses">
                <WarehousesPage />
              </Route>

              <Route path="/weight-calculator/:deliveryId">
                {(params) => (
                  <WeightCalculator deliveryId={params.deliveryId} />
                )}
              </Route>

              <Route path="/product-buys-calculator/:asin/:date">
                {(params) => (
                  <RightLayout>
                    <ProductsBuysCalculator
                      asin={params.asin}
                      date={params.date}
                    />
                  </RightLayout>
                )}
              </Route>

              <Route path="/athostests/">
                <RightLayout>
                  <Athostests />
                </RightLayout>
              </Route>

              <Route path="/order">
                <OrderPage />
              </Route>

              <Route path="/check-all-errors">
                <RightLayout>
                  <CheckAllErrorsPage />
                </RightLayout>
              </Route>

              <Route path="/settings">
                <RightLayout>
                  <SettingsPage />
                </RightLayout>
              </Route>

              <Route path="/new-entry-order">
                <NewEntryOrderPage mode="create" />
              </Route>

              <Route path="/entry-orders/:orderId">
                {(params) => (
                  <EntryOrderForm mode="edit" orderId={params.orderId} />
                )}
              </Route>

              <Route path="/entry-orders">
                <EntryOrdersPage />
              </Route>

              <Route path="/notes">
                <NotesPage />
              </Route>

              <Route path="/note/:noteId">
                {(params) => (
                  <RightLayout>
                    <NotesForm noteId={params.noteId} />
                  </RightLayout>
                )}
              </Route>

              <Route path="/refunds">
                <RightLayout>
                  <RefundsPage />
                </RightLayout>
              </Route>

              <Route path="/new-note">
                <RightLayout>
                  <NotesForm />
                </RightLayout>
              </Route>

              <Route path="/generate-delivery-to-aws">
                <GenerateDeliveryToAwsPage />
              </Route>

              <Route path="/generate-delivery-to-aws/new">
                <GenerateDeliveryToAwsPageNew />
              </Route>

              <Route path="/products">
                <AsinsPage />
              </Route>

              <Route path="/parents">
                <RightLayout>
                  <ParentsPage />
                </RightLayout>
              </Route>

              <Route path="/create-pack">
                <CreatePack />
              </Route>

              <Route path="/create-pack/:asin">
                <AsinsPage />
              </Route>

              <Route path="/products/:asin">
                {(params) => <ProductDetailsPage asin={params.asin} />}
              </Route>

              <Route path="/products/:asin/tags">
                {(params) => <EtiquetasSeguridad asin={params.asin} />}
              </Route>

              <Route path="/calendar">
                <CalendarPage />
              </Route>
              <Route path="">
                {/* <Redirect to="/products" /> */}
                <Redirect to="/calendar" />
              </Route>
              {/* Default route in a switch */}
              <Route>404: No such page!</Route>
            </Switch>

            {/* <Switch>
              <Route exact path="/products">
                <AsinsPage />
              </Route>
              <Route path="/products/:id">
                <ProductDetailsPage />
              </Route>
              <Route path="/">
                <LoginPage />
              </Route>
            </Switch> */}
          </div>
        </div>
      )}
    </>
  );
}
