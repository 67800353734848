import axios from "axios";
export class AsinService {
  getAllAsins() {
    return axios.get("/products");
  }

  getAsin(asin: string) {
    return axios.get(`/products/${asin}`);
  }

  async getBaseProductsOfAsin(asin: string) {
    const data = await this.getAsin(asin);
    const product = data.data;

    if (product.isBaseProduct) {
      return Promise.reject("Es un producto base");
    }
    return Promise.all(product.composedBy.map((asin) => this.getAsin(asin)));
  }

  createAsin(asin: string, warehouseId: string, unitsToCreate: number) {
    return axios.post("/products", { asin, warehouseId, unitsToCreate });
  }

  createPack(asin: string, warehouseId: string, unitsToCreate: number) {
    return axios.post(`/products/create-pack`, {
      asin,
      warehouse: warehouseId,
      unitsToCreate,
    });
  }

  updateAsin(asin: string, data: any) {
    return axios.patch(`/products/${asin}`, data);
  }

  deleteAsin(asin: string) {
    return axios.delete(`/products/${asin}`);
  }

  async hasEnaughUnits(asin: string, unitsNeeded: string, warehouse: string) {
    const data = await this.getAsin(asin);
    const product = data.data;
    const ware = product.warehouseStock.filter(
      (el) => el.warehouse === warehouse
    );
    if (ware[0].stock < unitsNeeded) {
      return Promise.reject("No hay suficientes unidades");
    } else {
      return Promise.resolve({ data: "Ok" });
    }
  }

  generateInstructionsManual(asin: string, language: string) {
    return axios.get(
      `/products/${asin}/${language}/generate-instructions-manual`
    );
  }

  downloadManualPdf(asin: string, language: string) {
    return axios.get(`/products/${asin}/${language}/download-manual-pdf`);
  }
}
